export const SimplefooterStyles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#333333',
    color: '#ffffff',
  },
  simpleFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#333333',
    color: '#ffffff',
  },
  detailedFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    backgroundColor: '#444444',
    color: '#eeeeee',
  },
  policies: {
    listStyleType: 'none',
    margin: '0',
    padding: '0',
    display: 'flex',
    gap: '16px',
  },
  policyLink: {
    color: '#eeeeee',
    textDecoration: 'none',
    fontSize: '1rem',
    transition: 'color 0.3s',
  },
  policyLinkHover: {
    color: '#ffffff',
  },
  socialIcons: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  socialButton: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    transition: 'opacity 0.3s',
  },
  socialButtonHover: {
    opacity: '0.7',
  },
  rights: {
    marginTop: '16px',
    fontSize: '0.875rem',
    textAlign: 'center',
  },
  templateFooter: {
    backgroundColor: '#4B5563',
    color: '#D1D5DB',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    textAlign: 'center',
  },
  templateSections: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
  },
  templateLogoTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px',
  },
  templateSocialIcons: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
  },
  templateRights: {
    fontSize: '0.875rem',
    alignSelf: 'center',
  },
  subscribeButton: {
    backgroundColor: 'rgb(51, 65, 85)',
    color: 'rgb(255, 255, 255)',
    padding: '12px 24px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'text',
    borderRadius: '4px',
    outline: 'none',
  },
};

export const DetailedFooterStyles = {
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center',
    justifyContent: 'space-evenly',
    gap: '16px',
    padding: '24px',
    backgroundColor: '#444444',
    color: '#eeeeee',
  },
  policies: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    listStyleType: 'none',
    padding: '0',
  },
  policyLink: {
    color: '#eeeeee',
    textDecoration: 'none',
    fontSize: '1rem',
    transition: 'color 0.3s',
  },
  policyLinkHover: {
    color: '#ffffff',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    alignItems: 'center',
  },
  rights: {
    marginTop: '16px',
    fontSize: '0.875rem',
    textAlign: 'center',
  },
  contactButton: {
    backgroundColor: 'rgb(51, 65, 85)',
    color: 'rgb(255, 255, 255)',
    padding: '12px 24px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'text',
    borderRadius: '4px',
    outline: 'none',

  }
};

export const TemplateFooterStyles = {
  footer: {
    backgroundColor: '#1F2937',
    color: '#D1D5DB',
    padding: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    borderTop: '2px solid #374151',
  },
  navigationLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  branding: {
    flex: 1,
    textAlign: 'center',
  },
  socialIcons: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

