
export const typeToTagMap = {
  div: 'div',
  section: 'section',
  heading: 'h1',
  h2: 'h2',
  h3: 'h3',
  paragraph: 'p',
  span: 'span',
  image: 'img',
  video: 'video',
  audio: 'audio',
  iframe: 'iframe',
  navbar: 'nav',
  hero: 'section',
  cta: 'section',
  footer: 'footer',
  button: 'button',
  input: 'input',
  form: 'form',
  ul: 'ul',
  ol: 'ol',
  li: 'li',
  table: 'table',
  tr: 'tr',
  td: 'td',
  anchor: 'a',
  textarea: 'textarea',
  select: 'select',
  option: 'option',
  label: 'label',
  fieldset: 'fieldset',
  legend: 'legend',
  progress: 'progress',
  meter: 'meter',
  blockquote: 'blockquote',
  code: 'code',
  pre: 'pre',
  hr: 'hr',
  caption: 'caption',
  date: 'input',
  mintingSection: 'section',
  ContentSection:'section',
  connectWalletButton: 'button',
  bgVideo: 'video',
  container: 'div',
  grid: 'div',
  hflex: 'div',
  vflex: 'div',
  line: 'hr',
  linkblock: 'div',
  youtube: 'iframe',
  icon:'img',
};

