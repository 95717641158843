export const CtaConfigurations = {  
    ctaOne: {
    children: [
      { type: "title", content: "Get Started Today!"},
      { type: "paragraph", content: "Sign up now and take the first step towards a better future."},
      { type: "button", content: "Join Now"},
      { type: "button", content: "Learn More"},
      { type: "image", content: "https://via.placeholder.com/300x200?text=CTA+Image" },
    ],
  },
  
  ctaTwo: {
    children: [
      { type: 'title', content: 'Take Action Now!' },
      { type: 'button', content: 'Primary Action' },
      { type: 'button', content: 'Secondary Action' },
    ],
  },
}